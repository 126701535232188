import React, { useState, useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import getVideoId from "get-video-id"
import moment from "moment"
import SearchResultsImagePopup from "@Components/SearchResults/SearchResultsImagePopup"
import SearchResultsFloorplanPopup from "@Components/SearchResults/SearchResultsFloorplanPopup"
import PlayVideo from "@Components/PlayVideo/PlayVideo"
import { useWindowSize } from "../hooks/window-size"
import Valuation from "@Components/SearchResults/Valuation/Valuation"
import UpcomingAuctionBanner from "@Components/SearchResults/Valuation/UpcomingAuctionBanner"
import { ShowProcessedImage } from "@Components/common/ggfx-client/module/components/show-image"
import LoadmorePagination from "@Components/SearchResults/LoadmorePagination"
import imageConfig from "../../static/images/config.json"
import NoImage from "../images/no-image.png"
import Content from "../Components/Content/Content"
import { Link } from "gatsby"
import { Form, Container, Row, Col, Fade, Modal } from "react-bootstrap"

import {
  UserObjectStoreProvider,
  SaveItem,
  getUserobjectData,
  isAuthenticated,
  savedSearchParams,
} from "@myaccount/website-service"

export const InfiniteHits = ({
  hits,
  searchState,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext,
  location,
  userObjects,
  searchParams,
  Alias,
  prevState,
  auction_date,
  department,
  status,
  total,
  hitsPerPage,
  current_page_number,
  number_of_pages,
  fullpathname
}, props) => {
  // Video
  const [isPlay, setIsPlay] = useState(false)
  const [windowWidth] = useWindowSize()
  const [property_hits, setPropertyHits] = useState([])

  const [videoid, setVideoId] = useState("")
  const [video_type, setVideoType] = useState("")
  const [error_flag, setErrorFlag] = useState(false)

  const playVideoHandler = (video_url, video_type) => {
    setIsPlay(true)
    setVideoId(video_url)
    setVideoType(video_type)
  }

  const [show, setShow] = useState(false)
  const [fullscreen, setFullscreen] = useState(true)

  function handleShow(video_url, video_type) {
    setShow(true)
    setVideoId(video_url)
    setVideoType(video_type)
  }
  // Video

  useEffect(() => {
    setErrorFlag(false)
    var property_hits = hits

    //var today = moment(new Date()).format("DD MMMM YYYY")
    var today = moment(new Date()).valueOf()

    if (Alias === "upcoming-auctions") {
      //console.log("auction_date", hits, hits.filter(hit => moment(hit?.startDate).format("YYYY-MM-DD") === auction_date), moment("2022-07-29T11:00:00").format("YYYY-MM-DD"), auction_date)

      if (auction_date) {
        property_hits = hits.filter(
          hit => moment(hit?.startDate).format("YYYY-MM-DD") === auction_date
        )
      } else {
        property_hits = hits.filter(
          //hit => moment(hit?.startDate).valueOf("DD MMMM YYYY") > today
          hit => moment(hit?.startDate).valueOf() > today
        )
      }
    }
    setPropertyHits(property_hits)

    setTimeout(() => {
      if (property_hits.length === 0) {
        setErrorFlag(true)
      }



    }, [1000])



  }, [hits, auction_date])

  useEffect(() => {
    typeof window !== "undefined" && window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, [])



  //console.log("property_hits ==>", property_hits.length);

  return (
    <>

      {property_hits?.length > 0 &&
        property_hits.map((hit, i) => {
          // property details url structure
          let uriStr = ""
          let mysale = ""

          if (hit.search_type === "sales" && hit.department === "residential") {
            uriStr = `property-for-sale/`
            mysale = `myResSaleProp`
          } else if (
            hit.search_type === "lettings" &&
            hit.department === "residential"
          ) {
            uriStr = `property-to-rent/`
            mysale = `myResRentProp`
          } else if (
            hit.search_type === "sales" &&
            hit.department === "commercial"
          ) {
            uriStr = `commercial-property-for-sale/`
            mysale = `myResSaleProp`
          } else if (
            hit.search_type === "lettings" &&
            hit.department === "commercial"
          ) {
            uriStr = `commercial-property-to-rent/`
            mysale = `myResRentProp`
          }
          // property details url structure

          // Video id
          let videotourid = hit.video_tour && getVideoId(hit.video_tour)

          let virtualid = hit.virtual_url && getVideoId(hit.virtual_url)
          // Video id

          var address = ""
          if (hit.address) {
            if (hit?.address?.buildingNumber) {
              address += hit?.address?.buildingNumber + " "
            }
            if (hit?.address?.buildingName !== null) {
              address += hit?.address?.buildingName + ", "
            }
            if (hit?.address?.address1 !== null) {
              address += hit?.address?.address1 + ", "
            }
            if (hit?.address?.address2 !== null) {
              address += hit?.address?.address2 + ", "
            }
            if (hit?.address?.address3 !== null) {
              address += hit?.address?.address3 + ", "
            }
            if (
              hit?.address?.town !== null &&
              hit?.address?.town !== hit?.address?.county
            ) {
              address += hit?.address?.town + ", "
            }
            if (hit?.address?.district !== null) {
              address += hit?.address?.district + ", "
            }
            if (hit?.address?.county !== null) {
              address += hit?.address?.county + ", "
            }
            if (hit?.address?.postcode !== null) {
              address += hit?.address?.postcode
            }
          }
          var display_address_val = hit.display_address
            ? hit.display_address
            : address
          var title_val = hit.title
            ? hit.title
            : hit.bedroom
              ? hit.bedroom + " bedrooms property for sale"
              : ""




          return (
            <>
              <UserObjectStoreProvider>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="">
                    <div className="property-card mb-2">
                      <div className="property-card-wrapper">
                        <div className="property-img">
                          {hit?.images && hit?.images?.filter(item => item !== null).length > 0 ? (
                            <a href={hit.lotDetailsUrl} target="_blank">
                              {/* <ShowProcessedImage images={hit.images[0]?.url} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults.sizes} /> */}

                              <ShowProcessedImage
                                images={hit.images[0]}
                                attr={{
                                  className: "mb-0 img-fluid",
                                  alt:
                                    display_address_val +
                                    " - DNG Estate Agents",
                                }}
                                transforms={
                                  imageConfig.property.searchResults.sizes
                                }
                              />

                              {/* <div className="property-wrapper">
                                                          <div className="similar-property-tag-text">New Release of 2,3 & 4 Bedroom Homes</div>
                                                      </div> */}
                              {hit.status &&
                                hit.status !== "For Sale" &&
                                hit.status !== "To Let" && (
                                  <div className="property-tag">
                                    {hit.status}
                                  </div>
                                )}
                            </a>
                          ) : (
                            <a href={hit.lotDetailsUrl} target="_blank">
                              <img
                                src={NoImage}
                                className="mb-0 img-fluid"
                                alt="property"
                              />
                              {hit.status &&
                                hit.status !== "For Sale" &&
                                hit.status !== "To Let" && (
                                <div className="property-tag">{hit.status}</div>
                              )}
                            </a>
                          )}
                          <div className="d-flex justify-content-between align-items-center auction-wrapper property-wrapper">
                            <div className="left-block">
                              <ul className="list-inline m-0 auction-wrapper-list">
                                {hit?.images && hit?.images?.length != 0 && (
                                  <SearchResultsImagePopup
                                    propertyId={hit.objectID}
                                    propertyImage={hit?.images}
                                    propertyImageLrg={hit?.images_src}
                                    imageCount={hit?.imageCount}
                                  />
                                )}
                                {hit.video_tour &&
                                  (videotourid?.service === "youtube" ? (
                                    <li className="list-inline-item">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          playVideoHandler(
                                            hit.video_tour,
                                            "youtube"
                                          )
                                        }
                                      >
                                        <i className="icon-play-black"></i>
                                      </a>
                                    </li>
                                  ) : (
                                    <li className="list-inline-item">
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          handleShow(
                                            hit.video_tour,
                                            "custom_video"
                                          )
                                        }
                                      >
                                        <i className="icon-play-black"></i>
                                      </a>
                                    </li>
                                  ))}
                                {/* <li className="list-inline-item">
                                                          <i className="icon-floorplan-black"></i>
                                                      </li> */}
                              </ul>
                            </div>

                            <SaveItem
                              type="property"
                              pid={hit.objectID}
                              userObjects={userObjects}
                            >
                              <div className="right-block">
                                {/* <button type="button">
                                    <i className="icon-heart" />
                                    <span className="btn-text d-none d-md-inline-block"></span>
                                  </button> */}
                                {/* <button>
                                            <i className={'icon-heart' } />
                                            <span className="span-btn-text d-none d-md-inline-block">{'save' }</span>
                                            <i className={'propertyData.isSaved' ? 'icon-heart filled' : 'icon-heart' } />
                                            <span className="span-btn-text d-none d-md-inline-block">{'propertyData.isSaved' ? 'saved' : 'save' }</span>
                                      </button> */}
                              </div>
                            </SaveItem>
                          </div>
                        </div>

                        <div className="property-details auction-details">
                          <div className="property-name">
                            <a href={hit.lotDetailsUrl} target="_blank">
                              {display_address_val}
                            </a>
                          </div>
                          {/* {
                                hit.bedroom >= 1 &&
                                <div className="property-bedroom-details pt-0 mb-3">
                                    <div className="mb-0 property-bedroom-text">{hit.bedroom} bedroom detached house for sale</div>
                                </div>
                            } */}

                          <div className="property-bedroom-details pt-0 mb-3">
                            <div className="mb-0 property-bedroom-text">
                              {hit.long_description &&
                                <Content
                                  Content={hit.long_description}
                                  page="auction-result"
                                  linkPage={hit.lotDetailsUrl}
                                />
                              }
                            </div>
                          </div>
                          <div className="auction-price-value">
                            {hit.price && (
                              <p className="newhme_info ">
                                <i className="icon icon-currency"></i> AMV: €
                                {hit.price.toLocaleString()}
                              </p>
                            )}
                          </div>
                          <div className="auction-end-time">
                            {hit.endDate && (
                              <p className="newhme_info mb-5 end_date">
                                <>
                                  <i className="icon icon-property-clock"></i>
                                  {Alias === "past-auctions" ? (
                                    <>
                                      Auction Ended{" "}
                                      {moment(hit.endDate).format("Do MMMM YYYY")}{" "}
                                      at {moment(hit.endDate).format("h.mma")}
                                    </>
                                  ) : (
                                    <>
                                      Auction Ends{" "}
                                      {moment(hit.endDate).format("Do MMMM YYYY")}{" "}
                                      at {moment(hit.endDate).format("h.mma")}
                                    </>
                                  )}
                                </>
                              </p>
                            )}
                          </div>

                          <div className="bottom-block">
                            <div class="valuation-cta w-100">
                              {/* <button type="button" class="btn w-100">View / Bid</button> */}
                              {Alias === "past-auctions" ? (
                                <a
                                  href={hit.lotDetailsUrl}
                                  target="_blank"
                                  class={`btn w-100 ${hit.lotDetailsUrl ? "" : "disabled"
                                    }`}
                                >
                                  View Result
                                </a>
                              ) : (
                                <a
                                  href={hit.lotDetailsUrl}
                                  target="_blank"
                                  class={`btn w-100 ${hit.lotDetailsUrl ? "" : "disabled"
                                    }`}
                                >
                                  View / Bid
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>

                {Alias !== "upcoming-auctions" && (i + 1) % 6 === 0 ? (
                  <UpcomingAuctionBanner />
                ) : (
                  ""
                )}
              </UserObjectStoreProvider>
            </>
          )
        })}

      {video_type === "youtube" ? (
        <PlayVideo
          isOpen={isPlay}
          videoId={videoid}
          stopPlay={() => {
            setIsPlay(false)
            setVideoType("")
          }}
        />
      ) : (
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => {
            setShow(false)
            setVideoType("")
          }}
          dialogClassName="modal-fullscreen property-details-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="p-0">
            <iframe src={videoid}></iframe>
          </Modal.Body>
        </Modal>
      )}

      {/* {property_hits.length > 21 &&
        <>
          {(total > hitsPerPage) && (current_page_number + 1) !== number_of_pages && (
            <LoadmorePagination total={total} hitsPerPage={hitsPerPage} current_page_number={current_page_number} number_of_pages={number_of_pages} location_path={fullpathname} />
          )}
        </>
      } */}

    </>
  )
}
