import React, { useState, useEffect } from "react"
import qs from "qs"
import { Form, Container, Row, Col, Fade, Modal } from "react-bootstrap"
import Select from "react-select"
import algoliasearch from "algoliasearch/lite"
import moment from "moment"
import isbot from 'isbot'
import Cookies from "universal-cookie"
import { navigate } from "@reach/router"
import { Link } from "gatsby"
import Breadcrumbs from "@Components/Static/Breadcrumbs/Breadcrumbs"

import Layout from "@Components/Layout/layout"
import SecondaryHeader from "@Components/SearchResults/SecondaryHeader/SecondaryHeader"

import ViewCalendar from "@Components/SearchResults/ViewCalendar"
import NoPropertyList from "@Components/SearchResults/NoAuctionPropertyList"
import CreateAlert from "@Components/SearchResults/CreateAlert"
import LoadmorePagination from "@Components/SearchResults/LoadmorePagination"
import LoadpreviousPagination from "@Components/SearchResults/LoadpreviousPagination"
import MapReults from "@Components/map/results"

import BotPropertyList from "@Components/SearchResults/BotAuctionPropertyList"

import { InfiniteHits } from "./auctionhits"
import SeoResults, {
  ACTION_TYPES
} from "../Components/property-search/seoResults"
import {
  parseSearchUrl,
  propertyH1
} from "../Components/property-search/utils"

import {
  UserObjectStoreProvider,
  SaveItem,
  getUserobjectData,
  isAuthenticated,
  savedSearchParams,
} from "@myaccount/website-service"
import PopularSearch from "../Components/StaticPageTwo/PopularSearches/PopularSearch"
import ReactMarkdown from "react-markdown"

//
const mycookies = new Cookies()
const updateAfter = 500
const DEBOUNCE_TIME = 500

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

var minsizeval = minsizeval || ""
var maxsizeval = maxsizeval || ""

var includeSoldLet = false

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)
const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
//

// Capitalize
const capitalize = s => {
  if (typeof s !== "string") return ""
  return s?.charAt(0).toUpperCase() + s?.slice(1)
}
// Capitalize



const getStatusList = (location, currentStatus, pType) => {
  let moreStatus = "";
  if (pType === "sales") {
    moreStatus = ""
  }
  if (pType === "lettings") {
    moreStatus = ""
  }

  var includeStatus = [currentStatus, moreStatus];
  if (location) {
    var query = ""
    let pathUri_main = ""

    let a1 = location.pathname.indexOf("/property/for-sale") > -1
    let a2 = location.pathname.indexOf("/property/to-rent") > -1
    let a3 = location.pathname.indexOf("/commercial-property/for-sale") > -1
    let a4 = location.pathname.indexOf("/commercial-property/to-rent") > -1

    if (a1 == true) {
      pathUri_main = location.pathname.split("/property/for-sale")
    } else if (a2 == true) {
      pathUri_main = location.pathname.split("/property/to-rent")
    } else if (a3 == true) {
      pathUri_main = location.pathname.split("/commercial-property/for-sale")
    } else if (a4 == true) {
      pathUri_main = location.pathname.split("/commercial-property/to-rent")
    } else {
      pathUri_main = location.pathname.split(location)
    }
    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }


        // // Status
        if (pathUri[vi].indexOf("includes-") >= 0) {
          let statusFilt1 = pathUri[vi].split("includes-");
          includeStatus = [currentStatus]
          if (statusFilt1[1]) {
            let statusFilt4 = statusFilt1[1].split("-and-")
            if (statusFilt4.length > 0) {
              for (let v = 0; v <= statusFilt4.length; v++) {
                if (typeof statusFilt4[v] !== "undefined") {
                  includeStatus.push(statusFilt4[v].replace(/-/g, " "))
                }
              }
            }
          }
        }

        // Status 

      }
    }
  }
  return includeStatus;
}

class SearchResultsTemplate extends React.Component {
  constructor(props) {
    super(props)
  }

  //
  state = {
    userObjects: {},
    mapview: false,
    status: getStatusList(typeof window !== "undefined" ? window.location : "", this.props.pstatustype, this.props.ptype),
    selectedAreas: [],
    energy_rating: 0,
    windowWidth: 0,
    windowHeight: 0,
    auction_date: "",
    infoText: false
  }

  //

  // Map view
  mapView = e => {
    navigate(
      this.props.location.pathname.replace(
        "auction-property",
        "auction-property-map"
      )
    )
  }
  // Map View
  // Grid view
  gridView = e => {
    navigate(
      this.props.location.pathname.replace(
        "auction-property-map",
        "auction-property"
      )
    )
  }
  // Grid View
  // Status change For sale or Sold
  statusChange = e => {
    this.setState({ status: e })
  }
  // Status change For sale or Sold

  // Search filter text
  searchFilterText = e => {

    this.setState({ infoText: !this.state?.infoText })

  }
  // Search filter text

  componentDidMount() {
    if (isAuthenticated) {
      console.log("Success1")
      const getUserObjects = async () => {
        try {
          const userObjects = await getUserobjectData()
          this.setState({
            userObjects: userObjects.data.data,
          })
          console.log("success")
        } catch (e) {
          console.log("Nope")
        }
      }
      getUserObjects()
    }

  }

  getAuctionDate = () => {
    var url = typeof window !== "undefined" ? window.location.pathname : ""
    let pathUri = url.split("/")

    for (let vi = 1; vi <= pathUri.length; vi++) {
      // check for area
      if (typeof pathUri[vi] === "undefined") {
        continue
      }
      if (pathUri[vi].indexOf("date-") >= 0) {
        this.setState({ auction_date: pathUri[vi].replace("date-", "") })
      }
    }
  }

  componentDidUpdate(prevProps) {
    //console.log("searchState ==> 2",prevProps);
    if (prevProps.location !== this.props.location) {
      if (this.props.Alias === "upcoming-auctions") {
        this.getAuctionDate()
        //console.log("window pathname ==>",pathUri, this.props.Alias);
      }
    }
  }

  componentWillMount() {

    if (this.props.Alias === "upcoming-auctions") {
      this.getAuctionDate()
      //console.log("window pathname ==>",pathUri, this.props.Alias);
    }
  }

  render() {
    const searchParams = parseSearchUrl(
      this.props.ptype,
      this.props.fullpathname,
      this.props.pcategorytype
    )

    let saveSearchParams = { department: this.props.pcategorytype }
    saveSearchParams = {
      ...saveSearchParams,
      ...savedSearchParams(searchParams),
    }

    let h1 = propertyH1(
      searchParams,
      this.props.pcategorytype,
      this.props.h1_areas
    )

    var pagenumber = 1;


    const areaName =
      this.props?.h1_areas?.length > 0
        ? this.props.h1_areas.join(" and ")
        : searchParams.areas
          ? searchParams.areas.toString().replace(/,/g, ", ").replace(/-/g, " ")
          : "ireland"

    const pType = searchParams.pType

    let propertyType = ""
    if (searchParams.type == "") {
      propertyType = "properties "
    } else {
      propertyType = searchParams.type
    }

    const actionStr = ACTION_TYPES[pType]

    var description = `Explore our latest collection of ${capitalize(
      propertyType
    )} ${actionStr} ${searchParams.areas}. Contact DNG estate agents in ${searchParams.areas
      } to find the right property for you.`

    const isPageLoadBg = typeof this.props.fullpathname === "undefined"

    var department =
      this.props.pcategorytype === "auction_commercial" ||
        this.props.pcategorytype === "auction_residential" ||
        this.props.pcategorytype === "auctions"
        ? "auctions"
        : this.props.pcategorytype

    const h1Text = h1?.replace(/-/g, " ")

    const h1Desc = `Explore our wide range of ${h1Text.replace("Property", "property")} with DNG Estate Agents. To filter these results, use our filter above, or for more information about ${department} properties
    ${this.props.pstatustype.toLowerCase().replace("let", "rent")} ${(searchParams.officeId != "") ? 'thorugh' : 'in'} ${capitalize(areaName)}, ${(searchParams.officeId != "") ? 'estate agents' : ''} please `

    const getPath = typeof window !== "undefined" && window.location.pathname;
    const kilkennyPath = getPath && getPath.includes("in-kilkenny/dng-office-fed");

    let mystatus = ''
    if (this?.state?.status) {
      if (Array.isArray(this?.state?.status)) {
        mystatus = '(status:"' + (this.state?.status).join('" OR status:"') + '")'
      } else {
        mystatus = '(status:"' + (this.state?.status) + '")'
      }
    }
    let realuser = true
    if (typeof window !== 'undefined') {
      realuser = (process.env.GATSBY_BlOCK_ALGOLIA_BOT_REQUEST == "true") ? (isbot(navigator.userAgent) ||  navigator.userAgent.indexOf("Prerender") > -1) : false
    }


    const sortby_options = [
      { value: '', label: 'Most Recent' },
      { value: 'price-desc', label: 'Highest Price' },
      { value: 'price-asc', label: 'Lowest Price' }
    ];
    let sortby_key;
    if (this.props?.page_url_data?.sortVal === process.env.GATSBY_ALGOLIA_INDEX_NAME) {
      sortby_key = 0;
    } else if (this.props?.page_url_data?.sortVal === process.env.GATSBY_ALGOLIA_INDEX_NAME + "_price_desc") {
      sortby_key = 1;
    } else if (this.props?.page_url_data?.sortVal === process.env.GATSBY_ALGOLIA_INDEX_NAME + "_price_asc") {
      sortby_key = 2;
    }

    const changesortby = event => {
      //get sortby value
      let sortbyUrl = '';
      if (event?.value) {
        sortbyUrl = 'sortby-' + event.value + '/'
      }
      //get current url
      const urlwithoutsort_filt = (this.props?.fullpathname).split("page-");
      const urlwithoutsort = urlwithoutsort_filt[0].split("sortby-");
      // concat url and navigate
      navigate(urlwithoutsort[0] + sortbyUrl)
    }

    return (
      <UserObjectStoreProvider>
        <Layout setFreeTextSearch={this.props.setFreeTextSearch}>
          {!isPageLoadBg && (
            <SeoResults
              title={h1}
              searchParams={searchParams}
              description={description}
              location={this.props.fullpathname}
              pcategorytype={this.props.pcategorytype}
              h1_areas={this.props.h1_areas}
            />
          )}
          {this.props.Alias === "past-auctions" ? (
            <>
              {/* {this.state.windowWidth > 768 && ( */}
              <Breadcrumbs
                Page={this.props.Pagename}
                alias={this.props.Alias}
              />
              {/* )} */}
            </>
          ) : ''}

            <>
              {this.props.Alias !== "past-auctions" && (<SecondaryHeader
                propertyType={this.props?.page_url_data.searchtypeVal}
                department={this.props?.page_url_data.departmentVal}
                statusChange={this.statusChange}
                status={this.state?.status}
                statusType={this.state?.status}
                setAreaList={areas => {
                  this.setState({ selectedAreas: areas })
                }}
                setOnLoadAreaList={areas => {
                  this.setState({ selectedAreas: areas })
                }}
                propertyMap={this.props?.page_url_data?.layoutVal}
                searchState={this.state?.searchState}
                tag="auction"
                Alias={this.props.Alias}
                freeTextSearch={this.props.setFreeTextSearch}
                free_text_search={this.props?.free_text_search}
                setH1Areas={this.props.setH1Areas}
                h1_areas={this.props.h1_areas}
                setEnegeryRating={value =>
                  this.setState({ energy_rating: value })
                }
                auction_date={this.state.auction_date}

              />)}

              <section className={this.props?.page_url_data?.layoutVal == "" ? "property-results" : "property-results pb-5 auction-map-page"} >
                <Container>
                  <Row>
                    <Col sm="12">


                      <div className="section-header d-flex flex-wrap">
                        {this.props.Alias === "past-auctions" ? (
                          <>
                            <h1 className="searched-property">
                              Past Auction Properties
                              {/* (<CustomStats />) */}
                            </h1>

                            <ViewCalendar auctionType="past-auction" />
                          </>
                        ) : (
                          <>
                            {this.props.Alias === "upcoming-auctions" ? (
                              <>
                                <h1 className="searched-property">
                                  {this.state.auction_date
                                    ? `Upcoming Auction Properties - ${moment(
                                      this.state.auction_date
                                    ).format("Do MMMM YYYY")}`
                                    : "Upcoming Auction Properties"}
                                </h1>
                              </>
                            ) : (
                              <h1 className="searched-property auction_list">
                                {h1.replace(/-/g, " ")}
                                {/* (<CustomStats />) */}
                                <a
                                  href="javascript:void(0)"
                                  onClick={this.searchFilterText}
                                >
                                  <i className="info-icon"></i>
                                </a>
                              </h1>
                            )}

                            {/* <div className="save-search">
                            <SaveItem
                              type="search"
                              searchParams={saveSearchParams}
                              userObjects={this.state.userObjects}
                            >
                              <button type="button">
                                <i className="icon-heart" />
                                <span className="btn-text d-md-none save-text">
                                  Save
                                </span>
                                <span className="btn-text d-none d-md-inline-block save-text">
                                  Save Search
                                </span>
                                <span className="btn-text d-none saved-text">
                                  Saved
                                </span>
                              </button>
                            </SaveItem>
                          </div> */}

                            <ViewCalendar />
                            {this.props.Alias !== "upcoming-auctions" &&
                              <>
                                {this.props?.page_url_data?.layoutVal == "" ? (
                                  <div className="map-wrap auction_map">
                                    <button type="button" onClick={this.mapView}>
                                      <i className="icon-tag"></i>
                                      <span className="btn-text d-md-none">map</span>
                                      <span className="btn-text d-none d-md-inline-block">
                                        show on map
                                      </span>
                                    </button>
                                  </div>
                                ) : (<div className="map-wrap auction_map">
                                  <button type="button" onClick={this.gridView}>
                                    <i className="icon-grid"></i>
                                    <span className="btn-text d-md-none">grid</span>
                                    <span className="btn-text d-none d-md-inline-block">
                                      show on grid
                                    </span>
                                  </button>
                                </div>)}
                              </>}

                            {this.props?.page_url_data?.layoutVal == "" ? <div className="filter-wrap d-none d-sm-block">
                              <div className="select-option dropdown">
                                <div className="custom-dropdown-sortby">
                                  <Select
                                    options={sortby_options}
                                    defaultValue={sortby_options[sortby_key]}
                                    value={sortby_options.value}
                                    className={"custom-dropdown-sortby"}
                                    classNamePrefix={"react-dropdown-select"}
                                    placeholder="Most recent"
                                    isSearchable={false}
                                    onChange={changesortby}
                                    components={{ DropdownIndicator: () => <i className="icon icon-select-dropdown-dark"></i>, IndicatorSeparator: () => null }}
                                  />
                                </div>
                              </div>
                            </div> : ''}
                          </>
                        )}
                      </div>

                    </Col>
                  </Row>

                  <p className={`search_text ${this.state?.infoText ? 'active' : ''}`} >
                    <ReactMarkdown source={h1Desc} allowDangerousHtml />
                    <Link to="/contact-dng-estate-agents/">contact us</Link>.
                  </p>
                </Container>
              </section>

              {this.props?.page_url_data?.layoutVal == "" &&
                <section className="result-section selling-list" id="residentail-search-lists">
                  <Container>
                    <Row>
                      <Col>
                        <div className="result-block">
                        {realuser === false ?
                          <>
                            <LoadpreviousPagination total={this.props.total} hitsPerPage={this.props.hitsPerPage} current_page_number={this.props.current_page_number} number_of_pages={this.props.number_of_pages} location_path={this.props.fullpathname} />
                            {this.props?.total > 0 &&
                              <InfiniteHits
                                location={this.props.fullpathname}
                                tag={this.props.ptypetag}
                                userObjects={this.state?.userObjects}
                                hits={this.props.hits}
                                Alias={this.props.Alias}
                                prevState={this.props.prevState}
                                auction_date={this.state.auction_date}
                                total={this.props.total}
                                hitsPerPage={this.props.hitsPerPage}
                                current_page_number={this.props.current_page_number}
                                number_of_pages={this.props.number_of_pages}
                                location_path={this.props.fullpathname}
                              />}
                            {(this.props.total > this.props.hitsPerPage) && (this.props?.current_page_number + 1) !== this.props?.number_of_pages && (
                              <LoadmorePagination total={this.props.total} hitsPerPage={this.props.hitsPerPage} current_page_number={this.props.current_page_number} number_of_pages={this.props.number_of_pages} location_path={this.props.fullpathname} />
                            )}
                          
                          {this.props?.total === 0 &&
                            <>
                              <CreateAlert searchParams={saveSearchParams} userObjects={this.state?.userObjects} />
                              <Row className="w-100">
                                <Col>
                                  <div className="result-block">
                                    <NoPropertyList status={this.props.pstatustype} department={this.props.pcategorytype} />
                                  </div>
                                </Col>
                              </Row>
                            </>
                          }
                          </>
                          :
                          <BotPropertyList status={this.props.pstatustype} department={this.props.pcategorytype} search_type={searchParams.pType} hits={this.props.hits} />
                        }

                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>}

              {this.props?.page_url_data?.layoutVal &&
                <>
                  {this.props?.total === 0 &&
                    <section className="result-section selling-list" id="residentail-search-lists">
                      <Container>
                        <Row>
                          <Col>
                            <CreateAlert searchParams={saveSearchParams} userObjects={this.state?.userObjects} />
                            <Row className="w-100">
                              <Col>
                                <div className="result-block">
                                  <NoPropertyList status={this.props.pstatustype} department={this.props.pcategorytype} />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </section>
                  }
                  <div className=" mapresults ">
                    {this.props?.total > 0 &&
                      <MapReults hits={this.props?.hits} department="auction" />
                    }
                  </div>
                </>
              }

              {searchParams?.areas && (
                <section className="popular-search-result">
                  <Container>
                    <Row>
                      <Col>
                        <PopularSearch
                          searchParams={searchParams}
                          department={this.props.pcategorytype}
                          SearchType={"Search_Result"}
                          status={this.props.pstatustype}
                          ptypetag={this.props.ptypetag}
                          areaName={areaName}
                        />
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </>
          
        </Layout>
      </UserObjectStoreProvider>
    )
  }
}

export default SearchResultsTemplate
