import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap"
import { Link } from "gatsby"
import getVideoId from "get-video-id"
import ScrollAnimation from "react-animate-on-scroll"
import moment from 'moment';
import {
    UserObjectStoreProvider,
    SaveItem
} from "@myaccount/website-service"

import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import SearchResultsImagePopup from "@Components/SearchResults/SearchResultsImagePopup2"
import SearchResultsFloorplanPopup from "@Components/SearchResults/SearchResultsFloorplanPopup"
import PlayVideo from "@Components/PlayVideo/PlayVideo"

import imageConfig from "../../../static/images/config.json"
import NoImage from "../../images/no-image.png"

//import {NoResultProperties} from '../../queries/common_use_query';
import { capitalize } from "lodash"

const BotAuctionPropertyList = (props) => {

    // Video
    const [isPlay, setIsPlay] = useState(false)
    const [videoid, setVideoId] = useState("")
    const [video_type, setVideoType] = useState("")
    const [hits, setHits] = useState([])

    //const {loading, error, data} =NoResultProperties(props.status, props.department);

    const playVideoHandler = (video_url, video_type) => {
        setIsPlay(true)
        setVideoId(video_url)
        setVideoType(video_type)
    }

    const [show, setShow] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)

    function handleShow(video_url, video_type) {
        setShow(true)
        setVideoId(video_url)
        setVideoType(video_type)
    }
    // Video

    // useEffect(() => {
    //     data?.properties?.length > 0 && setHits(data.properties)
    // }, [data])
    
    useEffect(() => {
        setHits(props.hits)
    }, [props.hits])
    

    return (
        <React.Fragment>
            {hits?.length > 0 && hits.map((hit, i) => {
                // property details url structure
                let uriStr = ""
                let mysale = ""

                if (hit.search_type === "sales" && hit.department === "residential") {
                    uriStr = `property-for-sale/`
                    mysale = `myResSaleProp`
                } else if (
                    hit.search_type === "lettings" &&
                    hit.department === "residential"
                ) {
                    uriStr = `property-to-rent/`
                    mysale = `myResRentProp`
                } else if (
                    hit.search_type === "sales" &&
                    hit.department === "commercial"
                ) {
                    uriStr = `commercial-property-for-sale/`
                    mysale = `myResSaleProp`
                } else if (
                    hit.search_type === "lettings" &&
                    hit.department === "commercial"
                ) {
                    uriStr = `commercial-property-to-rent/`
                    mysale = `myResRentProp`
                }
                // property details url structure

                // Video id
                let videotourid = hit?.video_tour?.url && getVideoId(hit.video_tour.url)

                let virtualid = hit?.virtual_url?.url && getVideoId(hit.virtual_url.url)
                // Video

                // ggfx images
                let processedImages = JSON.stringify({})
                if (hit?.imagetransforms?.images_Transforms) {
                  processedImages = hit.imagetransforms.images_Transforms
                }

                var address = "";
                if(hit.address){
                    if(hit?.address?.buildingNumber){
                    address += hit?.address?.buildingNumber+" "
                    }
                    if(hit?.address?.buildingName !== null){
                    address += hit?.address?.buildingName+", ";
                    }
                    if(hit?.address?.address1 !== null){
                    address += hit?.address?.address1+", ";
                    }
                    if(hit?.address?.address2 !== null){
                    address += hit?.address?.address2+", ";
                    }
                    if(hit?.address?.address3 !== null){
                    address += hit?.address?.address3+", ";
                    }
                    if(hit?.address?.town !== null && hit?.address?.town !== hit?.address?.county){
                    address += hit?.address?.town+", ";
                    }
                    if(hit?.address?.district !== null){
                    address += hit?.address?.district+", ";
                    }
                    if(hit?.address?.county !== null){
                    address += hit?.address?.county+", ";
                    }
                    if(hit?.address?.postcode !== null){
                    address += hit?.address?.postcode;
                    }
                }
                var display_address_val = hit.display_address ? hit.display_address : address;
                var title_val = hit.title ? hit.title : hit.bedroom ? hit.bedroom + " bedrooms property for sale" : "";

                return (
                <>
                    <UserObjectStoreProvider>
                        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <div className="property-card">
                            <div className="property-card-wrapper">
                                <div className="property-img">
                                {hit?.images && hit?.images.length != 0 ? (
                                    <a href={hit?.lotDetailsUrl} target="_blank">
                                        {hit?.images[0]?.url ? (
                                            <ImageTransform
                                                imagesources={hit?.images[0]?.url}
                                                renderer="srcSet"
                                                imagename="property.images.similarpropertytile"
                                                attr={{
                                                    alt: hit?.display_address + " -  DNG",
                                                }}
                                                imagetransformresult={processedImages}
                                                id={hit.id}
                                            />
                                        ) : (
                                            <img src={NoImage} alt="property" />
                                        )}
                                    </a>
                                ) : (
                                    <a href={hit?.lotDetailsUrl} target="_blank">
                                    <img src={NoImage} alt="property" />
                                    </a>
                                )}
                                <div className="property-wrapper">
                                    <div className="left-block">
                                    {hit?.images && hit?.images?.length != 0 && (
                                        <SearchResultsImagePopup
                                        propertyId={hit.id}
                                        propertyImage={hit?.images}
                                        propertyImageLrg={hit?.images_src}
                                        />
                                    )}
                                    {/* <div className="image-wrap">
                                        <i className="icon-camera"></i>
                                        {
                                            hit.images.length > 0 &&
                                            <div className="image-count">1/{hit.images.length}</div>
                                        }
                                    </div> */}
                                    <div className="property-view">
                                        {hit?.video_tour?.url &&
                                        (videotourid?.service === "youtube" ? (
                                            <div className="video-wrap">
                                            <button
                                                onClick={() =>
                                                playVideoHandler(
                                                    hit.video_tour.url,
                                                    "youtube"
                                                )
                                                }
                                            >
                                                <i className="icon-play"></i>
                                            </button>
                                            </div>
                                        ) : (
                                            <div className="video-wrap">
                                            <button
                                                onClick={() =>
                                                handleShow(hit.video_tour.url, "custom_video")
                                                }
                                            >
                                                <i className="icon-play"></i>
                                            </button>
                                            </div>
                                        ))}

                                        {hit?.virtual_tour?.url &&
                                        (virtualid?.service === "youtube" ? (
                                            <div className="video-wrap">
                                            <button
                                                onClick={() =>
                                                playVideoHandler(
                                                    hit.virtual_tour.url,
                                                    "youtube"
                                                )
                                                }
                                            >
                                                <i className="icon-play"></i>
                                            </button>
                                            </div>
                                        ) : (
                                            <div className="video-wrap">
                                            <button
                                                onClick={() =>
                                                handleShow(
                                                    hit.virtual_tour.url,
                                                    "custom_video"
                                                )
                                                }
                                            >
                                                <i className="icon-play"></i>
                                            </button>
                                            </div>
                                        ))}

                                        {hit?.floorplan?.url ?
                                        <SearchResultsFloorplanPopup
                                            propertyId={hit.id}
                                            propertyFloorPlan={hit?.floorplan}
                                        />
                                        : null
                                        }
                                    </div>
                                    </div>
                                    <SaveItem
                                    type="property"
                                    pid={hit.id}
                                    //userObjects={userObjects}
                                    >
                                    <div className="right-block">
                                        <button type="button">
                                        <i className="icon-heart" />
                                        <span className="btn-text d-none d-md-inline-block"></span>
                                        </button>
                                    </div>
                                    </SaveItem>
                                </div>
                            </div>

                            <div className="property-details auction-details">
                                <div className="property-name">
                                    <a href={hit?.lotDetailsUrl} target="_blank">
                                        {display_address_val}
                                    </a>
                                </div>
                                {/* {
                                    hit.bedroom >= 1 &&
                                    <div className="property-bedroom-details pt-0 mb-3">
                                        <div className="mb-0 property-bedroom-text">{hit.bedroom} bedroom detached house for sale</div>
                                    </div>
                                } */}

                                    <div className="property-bedroom-details pt-0 mb-3">
                                        <div className="mb-0 property-bedroom-text">{capitalize(title_val)}</div>
                                    </div>

                                {
                                    hit.price &&
                                    <p className="newhme_info ">
                                        <i className="icon icon-currency"></i> AMV: €{hit.price.toLocaleString()}
                                    </p>
                                }
                                
                                {hit?.extras?.onlineAuction?.EndDate && 
                                <p className="newhme_info mb-5 ">
                                    <>
                                        <i className="icon icon-property-clock"></i> 
                                        <>Auction Ends {moment(hit?.extras?.onlineAuction?.EndDate).format('Do MMMM YYYY')} at {moment(hit?.extras?.onlineAuction?.EndDate).format('h.mma')}</>
                                    </>                                              
                                </p>
                                }

                                <div className="bottom-block">
                                    <div class="valuation-cta w-100">
                                        {/* <button type="button" class="btn w-100">View / Bid</button> */}
                                        <a href={hit.lotDetailsUrl} target="_blank" class={`btn w-100 ${hit.lotDetailsUrl ? '' : 'disabled'}`}>View / Bid</a>
                                    </div>
                                </div>
                            </div>

                           </div>
                        </div>
                        </ScrollAnimation>
                    </UserObjectStoreProvider>
                </>
                )
            })}

            {video_type === "youtube" ? (
                <PlayVideo
                isOpen={isPlay}
                videoId={videoid}
                stopPlay={() => {
                    setIsPlay(false)
                    setVideoType("")
                }}
                />
            ) : (
                <Modal
                show={show}
                fullscreen={fullscreen}
                onHide={() => {
                    setShow(false)
                    setVideoType("")
                }}
                dialogClassName="modal-fullscreen property-details-modal"
                >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="p-0">
                    <iframe src={videoid}></iframe>
                </Modal.Body>
                </Modal>
            )}
        </React.Fragment>
    )
}

export default BotAuctionPropertyList;