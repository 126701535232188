import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { Link, navigate } from "gatsby"
import $ from "jquery"
import moment from "moment"

import FullCalendar from "@fullcalendar/react"
import { Calendar, Tooltip } from "@fullcalendar/core"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"

import "./ViewCalendar.scss"
import { URLS } from "../../Components/common/site/constants"

import { FutureAuctions } from "../../queries/common_use_query"

const ViewCalendar = props => {
  const calendarComponentRef = React.createRef()
  const [show, setShow] = useState(false)
  const [auctionData, setAuctionData] = useState([])
  const [futureAuctionData, setFutureAuctionData] = useState([])

  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
  }

  const { loading, error, data } = FutureAuctions(
    "For Sale",
    "auction_residential"
  )

  const actionType = props.auctionType ? props.auctionType : ""

  // var calendar_json = [
  //     {
  //         "date": "2022-07-27T08:30:00.000Z",
  //         "start": "2022-07-27T08:30:00.000Z",
  //         "title": "Testing"
  //     },
  //     {
  //         "date": "2022-07-27T09:30:00.000Z",
  //         "start": "2022-07-27T09:30:00.000Z",
  //         "title": "Testing"
  //     },
  //     {
  //         "date": "2022-07-27T10:00:00.000Z",
  //         "start": "2022-07-27T10:30:00.000Z",
  //         "title": "Testing"
  //     },
  //     {
  //         "date": "2022-08-10T10:00:00.000Z",
  //         "start": "2022-08-10T10:30:00.000Z",
  //         "title": "Testing"
  //     },
  // ]

  useEffect(() => {
    setTimeout(function () {
      $(".calendar_popup .fc-daygrid-event-harness")
        .parent()
        .parent()
        .parent(".fc-daygrid-day")
        .addClass("active_cls")
    }, 500)

    $("body").on("click", ".calendar_popup button.fc-prev-button", function () {
      //do something
      setTimeout(function () {
        $(".calendar_popup .fc-daygrid-event-harness")
          .parent()
          .parent()
          .parent(".fc-daygrid-day")
          .addClass("active_cls")
      }, 300)
    })

    $("body").on("click", ".calendar_popup button.fc-next-button", function () {
      //do something
      //alert("test2");
      setTimeout(function () {
        $(".calendar_popup .fc-daygrid-event-harness")
          .parent()
          .parent()
          .parent(".fc-daygrid-day")
          .addClass("active_cls")
      }, 300)
    })
  })

  useEffect(() => {
    var get_data = data?.properties
    //console.log("auction_list get_data", get_data);


    var today = moment(new Date()).valueOf()

    // Filter Future Auction
    // var auction_list = get_data?.length > 0 && get_data?.filter(item =>  moment(item?.extras?.onlineAuction?.StartDate).format('DD MMMM YYYY') > today).map(item =>  ({"date": item?.extras?.onlineAuction?.StartDate,"start": item?.extras?.onlineAuction?.StartDate, "custom_date": moment(item?.extras?.onlineAuction?.StartDate).format('DD MMMM YYYY'), "id": item.id}));

    var auction_list =
      get_data?.length > 0 &&
      get_data
        ?.filter(
          item =>
            moment(item?.extras?.onlineAuction?.StartDate).valueOf() > today
        )
        .map(item => ({
          date: item?.extras?.onlineAuction?.StartDate,
          start: item?.extras?.onlineAuction?.StartDate,
          custom_date: moment(item?.extras?.onlineAuction?.StartDate).format(
            "DD MMMM YYYY"
          ),
          id: item.id,
        }))

    //console.log("auction_list filter", auction_list, moment("2022-07-07T12:00:00").valueOf() )

    const unique_dates = auction_list?.length > 0 && [
      ...new Set(auction_list.map(item => item.custom_date)),
    ]

    var calendar_json = []

    unique_dates.length > 0 &&
      unique_dates.map(item => {
        var filter_date = auction_list.filter(
          item2 => item2.custom_date === item
        )

        var lots = filter_date?.length > 1 ? " lots" : " lot"

        calendar_json.push({
          date: filter_date[0]?.date,
          start: filter_date[0]?.start,
          custom_date: item,
          title: filter_date.length + lots,
        })
      })

    setFutureAuctionData(calendar_json)

    //console.log("auction_list result", unique_dates);
  }, [data])

  const handleEventClick = events => {
    //console.log("events", events.el);
    //console.log("events2", events.event._def);

    handleClose()
    var auction_date = moment(
      events.event._def.extendedProps.custom_date
    ).format("YYYY-MM-DD")

    navigate(URLS.Upcoming_Auctions.alias + "/date-" + auction_date + "/", {
      state: {
        future_auction_date: events.event._def.extendedProps.custom_date,
      },
    })
    typeof window !== undefined && window.scrollTo(0, 0)

    // $('.calendar_popup .fc-daygrid-event-harness').removeClass('actv');
    // $(events.el.parentElement).addClass('actv');
    // $(".calendar_popup .fc-scrollgrid-sync-table").find('.fc-daygrid-day.active_cls').addClass('light_red').removeClass('actv_cls2');
    // $(events.el.parentElement.parentElement.parentElement.parentElement).removeClass('light_red').addClass('actv_cls2');
    //PropertyLaunchDateTimeDispatch(events.event._def.extendedProps);
  }


  const sortedAuction=futureAuctionData.sort((a,b)=>
    a.date>b.date?1:-1
      
  )
 
  return (
    <React.Fragment>
      <a
        href={"javascript:;"}
        className={`btn upcmg_cal_btn ${actionType}`}
        onClick={handleShow}
      >
        View Calendar
      </a>

      <Modal
        show={show}
        centered={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w modal-form modal-form-buy-sell-rent calendar_popup"
      >
        <Modal.Header closeButton className="contact-close-btn"></Modal.Header>
        <Modal.Body className="form">
          {sortedAuction.length > 0 ? (
            <FullCalendar
              selectable={true}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              ref={calendarComponentRef}
              weekends={true}
              events={sortedAuction}
              datesRender={sortedAuction}
              eventClick={handleEventClick}
              displayEventTime={false}
              showNonCurrentDates={false}
              initialDate={
                sortedAuction.length > 0 && sortedAuction[0].start
                  ? sortedAuction[0].start
                  : ""
              }
              eventTimeFormat={{
                hour: "numeric",
                minute: "2-digit",
                meridiem: "long",
              }}
              height={"670px"}
            />
          ) : (
            <FullCalendar
              selectable={true}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              ref={calendarComponentRef}
              weekends={true}
              displayEventTime={false}
              showNonCurrentDates={false}
              eventTimeFormat={{
                hour: "numeric",
                minute: "2-digit",
                meridiem: "long",
              }}
              height={"670px"}
            />
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default ViewCalendar
