/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import {
  ACTION_TYPES,
  getPriceStr,
  toTitleCase,
  PROPERTY_TYPES_H1,
  getSizeStr,
} from "./utils"
import { useLocation } from "@reach/router"

import _ from "lodash"
export { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr } from "./utils"

const SeoResults = ({
  description,
  lang,
  meta,
  title,
  searchParams,
  location,
  pcategorytype,
  h1_areas,
}) => {
  var { pathname } = useLocation()
  const { areas, bedrooms, pType, type, price, commType, mustInclude, size, officeId } =
    searchParams

  var replace_url = pcategorytype + "_" + pType
  var actionStr = ACTION_TYPES[replace_url]
 
  if(officeId != ""){
    actionStr = ACTION_TYPES[replace_url].replace("in", "through") 
  } 

  const priceStr = getPriceStr(price)
  const sizeStr = getSizeStr(size)

  const bedRooms = searchParams.bedrooms
  const pricerange = searchParams.price

  const capitalize = s => {
    if (typeof s !== "string") return ""
    return s?.charAt(0).toUpperCase() + s?.slice(1)
  }

  let areasDesc = ""
  if (typeof areas == "string") {
    areasDesc = areasDesc
  } else {
    areasDesc = areas.join(" and ")
  }

  if (h1_areas?.length > 0) {
    areasDesc = h1_areas.join(" and ")
  } else {
    areasDesc = _.upperFirst(areasDesc)
  }

  if(officeId != ""){
    areasDesc  =  areasDesc + " estate agents"
  }

  let propertyType = ""

  if (type == "") {
    propertyType =
      pcategorytype === "auction_residential" ? "auctions" : "properties"
  } else if (type == "apartment") {
    propertyType = "apartments"
  } else if (type == "house") {
    propertyType = "houses"
  } else if (type == "bungalow") {
    propertyType = "bungalow"
  } else {
    propertyType = capitalize(type.replace(/-/g, " "))
  }

  let desc = ""

  var bedroom_str =
    bedrooms > 0 ? (parseInt(bedrooms) === 1 ? "bedroom" : "bedrooms") : ""

  //  desc = "Explore our collection of "+ propertyType + actionStr + areasDesc +" Contact our DNG estate agents to find the right property"+ actionStr + areasDesc;

  //console.log("areasDesc", areasDesc)

  // Auction Meta Description
  if (pcategorytype === "auction_residential") {
    if (areasDesc === "ireland") {
      desc = `Explore our latest collection of auction property available ${actionStr} ${areasDesc}. Contact DNG Estate Agents in ${areasDesc} to get assistance in buying or selling property at auctions.`
    } else {
      desc = `Explore our range of auction property available ${actionStr} ${areasDesc}. Contact our property experts to get your desired property through auction in ${areasDesc}.`
    }
    if (bedrooms) {
      desc = `Explore auction property ${actionStr} ${areasDesc} with more than ${bedrooms} bedrooms here. Contact our expert estate agents today to get assistance in finding the right property in ${areasDesc}.`
    }

    if (pricerange.min || pricerange.max) {
      desc = `Find the right auction property ${actionStr} ${areasDesc} ${priceStr} with DNG Estate Agents. Book an appointment with one of our property experts in ${areasDesc}.`
    }

    if ((pricerange.min || pricerange.max) && bedrooms) {
      desc = `Explore the list of property ${actionStr} auction at ${areasDesc} with more than ${bedrooms} bedrooms ${priceStr} with DNG. Contact our expert auctioneers today to get assistance.`
    }
  }

  // Commercial Meta Description
  if (pcategorytype === "commercial") {
    if (areasDesc === "ireland") {
      desc = `Explore our latest collection of Commercial property ${actionStr} ${areasDesc}. Contact DNG Estate Agents in ${areasDesc} to arrange a viewing.`
    } else {
      desc = `Explore our range of Commercial property ${actionStr} ${areasDesc}. Contact our Commercial property experts to find the right property ${actionStr}  ${areasDesc}.`
    }

    if (type) {
      desc = `Looking for a ${
        PROPERTY_TYPES_H1[type.replace(/-/g, "_")]
      } ${actionStr} ${areasDesc}? DNG commercial estate agents are here to help. `
    }

    if (sizeStr) {
      desc = `Identify the commercial property ${actionStr} ${areasDesc} ${sizeStr}. Book a viewing with DNG commercal estate agents.`
    }

    if (pricerange.min || pricerange.max) {
      desc = `Find the right Commercial property ${actionStr} ${areasDesc} ${priceStr} with DNG Estate Agents. Book an appointment with one of our property experts in ${areasDesc}.`
    }

    if ((pricerange.min || pricerange.max) && type) {
      desc = `Check the latest collection of Commercial property ${
        PROPERTY_TYPES_H1[type.replace(/-/g, "_")]
      } ${actionStr} ${toTitleCase(
        areasDesc
      )} ${priceStr} with DNG Estate Agents and letting agents in ${toTitleCase(
        areasDesc
      )}.`
    }

    if (sizeStr && type) {
      desc = `Looking for a ${
        PROPERTY_TYPES_H1[type.replace(/-/g, "_")]
      } ${actionStr} ${toTitleCase(
        areasDesc
      )} ${sizeStr}? DNG commercial estate agents are here to help.`
    }
  }

  // Residential Meta Description
  if (pcategorytype === "residential") {
    if (areasDesc === "ireland") {
      desc = `Explore our latest collection of property ${actionStr} ${areasDesc}. Contact DNG Estate Agents in ${areasDesc} to find the right property for you.`
    } else {
      desc = `Explore our range of property ${actionStr} ${areasDesc}. Contact our property experts to find the right property ${actionStr} ${areasDesc}.`
    }

    if (type) {
      desc = `Looking for a ${
        PROPERTY_TYPES_H1[type.replace(/-/g, "_")]
      } ${actionStr} ${areasDesc}? DNG Estate Agents are here to help. `
    }

    if (bedrooms) {
      desc = `Explore property ${actionStr} ${areasDesc} with more than ${bedrooms} bedrooms here. Contact our expert estate agents today to get assistance in finding the right property in ${areasDesc}.`
    }

    if (pricerange.min || pricerange.max) {
      desc = `Locate the right property ${actionStr} ${areasDesc} ${priceStr} with DNG Estate Agents. Book an appointment with one of our property experts in ${areasDesc}.`
    }

    if ((pricerange.min || pricerange.max) && type) {
      desc = `Check out the latest collection of ${
        PROPERTY_TYPES_H1[type.replace(/-/g, "_")]
      } ${actionStr} ${areasDesc} ${priceStr} with DNG Estate Agents in ${areasDesc}.`
    }

    if ((pricerange.min || pricerange.max) && bedrooms) {
      desc = `Explore the list of property ${actionStr} ${areasDesc} with more than ${bedrooms} bedrooms ${priceStr} with DNG and request a viewing for the property that interests you.`
    }

    if (bedrooms && type) {
      desc = `Find the latest collection of ${
        PROPERTY_TYPES_H1[type.replace(/-/g, "_")]
      } ${actionStr} ${areasDesc} with more than ${bedrooms} bedrooms. Contact DNG Estate Agents and letting agents in ${areasDesc}, to arrange a viewing.`
    }

    if (bedrooms && type && (pricerange.min || pricerange.max)) {
      desc = `Explore ${bedrooms} ${bedroom_str} ${
        PROPERTY_TYPES_H1[type.replace(/-/g, "_")]
      } ${actionStr} ${areasDesc} ${priceStr} available with DNG. Contact one of our estate agents for assistance in finding your property in ${areasDesc}.`
    }
  }

  //    else {
  //      // price
  //      if (price.min || price.max){
  //        desc = propertyType + actionStr + areas + "."
  //      }
  //    }

  //
  let titlePropertyType = ""

  if (type == "") {
    titlePropertyType =
      pcategorytype === "auction_residential"
        ? "Auction properties"
        : "properties"
  } else if (type == "apartment") {
    titlePropertyType = "Apartments"
  } else if (type == "house") {
    titlePropertyType = "Houses"
  } else if (type == "maisonette") {
    titlePropertyType = "Maisonette"
  } else if (type == "property") {
    titlePropertyType = "Property"
  } else if (type == "bungalow") {
    titlePropertyType = "Bungalow"
  } else if (type == "land") {
    titlePropertyType = "Land"
  } else if (type == "commercial") {
    titlePropertyType = "Commercial"
  } else {
    titlePropertyType = type
  }

  let bedroomType = ""

  if (bedrooms) {
    bedroomType = bedrooms + " + Bedroom "
  }

  //let descTitle = bedroomType + toTitleCase(titlePropertyType.replace(/-/g, ' ')) + actionStr + areasDesc + priceStr

  let descTitle = title
  //

  // console.log("Props Desc => ", desc);

  description = desc

  let metaItems = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `fragment`,
      content: `!`,
    },
    {
      property: `og:title`,
      content: descTitle,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: `DNG`,
    },
    {
      name: `twitter:title`,
      content: descTitle,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ].concat(meta)

  var currentUrl = ""
  currentUrl = process.env.GATSBY_SITE_URL + pathname

  // set no index for result pagination
  let hasPagination = false
  if (pathname !== undefined && pathname) {
    let pageStr = pathname.split("page-")
    hasPagination = pageStr[1] || false
    if (pageStr[0] !== undefined) {
      currentUrl = process.env.GATSBY_SITE_URL + pageStr[0]
    }
  }

  if (!currentUrl.endsWith("/")) currentUrl = currentUrl + "/"

  //remove multiple slashes
  currentUrl = currentUrl.replace(/([^:]\/)\/+/g, "$1")
  //set lowercase url
  currentUrl = currentUrl.toLowerCase()

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          href: currentUrl,
          rel: "canonical",
        },
      ]}
      title={descTitle}
      titleTemplate={`%s | DNG Estate Agents`}
      meta={metaItems}
    />
  )
}

SeoResults.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ``,
  searchParams: [],
}

SeoResults.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  searchParams: PropTypes.any,
}

export default SeoResults
