import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./valuation.scss"
import { Valuation_Page_Url } from "@Components/common/site/constants"
import ViewCalendar from "@Components/SearchResults/ViewCalendar"
import { Link } from "gatsby"
const UpcomingAuction = () => {
  return (
    <section className="valuation w-100 mb-5 marketing_property">
      <Row>
        <Col>
          <div className="valuation-banner property-list">
            <div className="valuation-details auction_bnr">
              <i className="icon-home"></i>
              <h3>
                 Upcoming <span className="highlight">Auctions</span>
              </h3>
              <p>Click here to view a calendar listing of all our upcoming auctions across the DNG network.</p>
            </div>
            <div className="valuation-cta">
              {/* <a href={"javascript:;"} className="btn">
                View Calendar
              </a> */}
              <ViewCalendar />
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default UpcomingAuction